import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ContactForm from '../components/contact-form'

import ImageAdrian from '../components/images/image-adrian'

import Layout from '../components/layout'
import SEO from '../components/seo'


const Grid = styled.div`
 display:grid;
 grid-gap: 20px;
 grid-template-columns: 1fr 40%;

 div:last-child {
   padding: .750rem;
 }

 @media ( max-width: 900px) {
    grid-template-columns: 100%;

    div:last-child {
      padding: 0;
    }
  }

`

const AgendarCitaPage = ( ) => (
  <Layout>
    <SEO title="el Metodo Mckenzie Agendar Cita" />
    
    <Grid>
      <div>
      <h2>Agendar Una Cita</h2>
      <p>Para Agendar una cita sírvase llenar el formulario a continuación o puede llamar a los teléfonos: +506 8483 7383 o al +506 2229-0318 o escribirnos un correo a <a href="mailto:adrian@fisio.cr">adrian@fisio.cr</a> </p>

     < ContactForm /> 
    
    <Link to="/" className={`mdt-button-simple`}>Volver al Inicio</Link>
    </div>
    <div style={{
      paddingTop: '2.75rem',
    }}>
      <ImageAdrian />
      <p>Primer certificado en Centroamérica en el Método McKenzie. 
        Reconocido internacionalmente como tratamiento líder para problemas de columna lumbar, de cuello y extremidades.</p>
    </div>
    </Grid>
  </Layout>
)

export default AgendarCitaPage
