import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
width: 100%;
display: inline-block;
margin-top: .75rem;
`
const Input = styled.input`
    width: 100%;
    padding: 10px;
    background: #ffffff78;
    border: solid 2px #c5bba450;
    border-radius: 10px;

    ::placeholder {
      color: #c5bba450;
    }
`

const TextArea = styled.textarea`
    width: 100%;
    padding: 10px;
    background: #ffffff78;
    border: solid 2px #c5bba450;
    border-radius: 10px;

    ::placeholder {
      color: #c5bba450;
    }
`

const InputSubmit = styled.input`
    padding: 10px 20px;
    border: 2px solid #c5bba469;
    border-radius: 10px;
    color: #f5e9c3;
    background: #000000;
    margin: .750rem 0;
`

const Select = styled.select`  
    width: 100%;
    padding: 10px 20px;
    background: #ffffff78;
    border: solid 2px #c5bba450;
    border-radius: 10px;
    option {}
`
 

    class ContactForm extends React.Component { 
        constructor(props) {
            super(props);
            this.state = {value: 'coronado'};
        
            this.handleChange = this.handleChange.bind(this);
          }
        
          handleChange(event) {
            this.setState({value: event.target.value});
          }   
            
        render() {
            return (
        
    <form name="contact-form" method="POST" action="/success/" data-netlify="true" data-netlify-honeypot="bot-field" key="contact-form">

        <input type="hidden" name="bot-field" />  

        <Label htmlFor="fname">Nombre Completo</Label>
        <Input type="text" id="fname" name="firstname" placeholder="Nombre Completo" required/>

        <Label htmlFor="lemail">Correo Electrónico</Label>
        <Input type="text" id="lemail" name="email" placeholder="Correo Electronico" required/>
        
        <Label htmlFor="lphone">Teléfono</Label>
        <Input type="text" id="lphone" name="phone" placeholder="Telefono" required/>

        <Label htmlFor="lsede">Seleccione la clínica mas cerca de usted: </Label>
        <Select id="lsede" name="sede[]" value={this.state.value} onChange={this.handleChange} required>
            <option value="coronado">Coronado</option>
            <option value="santaana">Santa Ana</option>
        </Select>

        <Label htmlFor="subject">Indíquenos su Dolencia</Label>
        <TextArea id="subject" name="subject" placeholder="Indiquenos su Dolencia" required>
        </TextArea>
        <input type="hidden" name="form-name" value="contact" />
        <InputSubmit type="submit" value="Enviar" name="submit" />

    </form>
            )
        }
    }
      export default ContactForm